@import "../../data/styles.css";

.homepage-logo-container {
 display: flex;
 justify-content: left;
 align-items: center;
 padding-top: 130px;
}

.homepage-container {
 padding-top: 25px;
}

.homepage-first-area {
 display: flex;
 justify-content: space-between;
 align-items: center;
}

.homepage-first-area-left-side {
 display: flex;
 flex-direction: column;
 align-items: flex-start;
}

.homepage-title {
 width: 85% !important;
}

.homepage-subtitle {
 width: 85% !important;
}

.homepage-first-area-right-side {
 display: flex;
 align-items: center;
}

.homepage-image-container {
 width: 350px;
 height: 350px;
}

.homepage-image-wrapper {
 overflow: hidden;
 border-radius: 100px;
 object-fit: fill;
}

.homepage-image-wrapper img {
 width: 100%;
 height: 100%;
}

.homepage-socials {
 display: flex;
 justify-content: left;
 align-items: center;
 padding-top: 30px;
}

.homepage-social-icon {
 padding-right: 20px;
 font-size: 25px;
 transition: color 0.2s ease-in-out;
 color: var(--secondary-color);
}

.homepage-social-icon:hover {
 color: var(--tertiary-color);
}

.homepage-after-title {
 display: flex;
}

.homepage-projects {
 flex-basis: 300px;
 padding-top: 40px;
}

.homepage-articles {
 flex-basis: 900px;
 padding-top: 30px;
 margin-left: -30px;
}

.homepage-article {
 padding-bottom: 5px;
}

.homepage-works {
 flex-basis: 600px;
 padding-top: 30px;
}
.homepage-resume{
 color: var(--primary-color);
 padding-top: 15px;
 margin-bottom: 1em;
 font-weight: 700;
}
.homepage-resume-description{
 display: flex;
 align-items: center;
 color: var(--secondary-color);
 font-size: 17px;
 padding-top: 20px;
 text-decoration: none;
 cursor: pointer;
}
@keyframes rainbow {
	0% { color: red; }
	14% { color: orange; }
	28% { color: yellow; }
	42% { color: green; }
	57% { color: blue; }
	71% { color: indigo; }
	85% { color: violet; }
	100% { color: red; }
}
a {
	 text-decoration: none;
}
.homepage-resume-description:hover{
 animation: rainbow 4s infinite;
}
@media (max-width: 1024px) {
 .homepage-first-area {
  flex-direction: column;
 }

 .homepage-first-area-left-side {
  display: flex;
  flex-direction: column; /* Changed: set direction to column */
  align-items: flex-start;
  width: 100%;
  order: 2;
 }

 .homepage-title {
  width: 100% !important;
  margin-top: 20px;
  order: 2;
 }

 .homepage-subtitle {
  width: 100% !important;
  margin-top: 10px;
  order: 3;
 }

 .homepage-first-area-right-side {
  width: 100%;
  order: 1;
 }

 .homepage-image-container {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: 10px;
  order: 1;
 }

 .homepage-after-title {
  flex-direction: column;
 }

 .homepage-projects {
  flex-basis: auto;
 }

 .homepage-articles {
  flex-basis: auto;
 }

 .homepage-works {
  flex-basis: auto;
  margin-left: -25px;
  margin-right: -25px;
 }
}